import React from "react";
import styles from "./task-list-table.module.css";
import { Task } from "../../types/public-types";

export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  hoverColor: string;
  visibleColumns: any[];
  readOnly: boolean;
  hoveredRow: number;
  setHoveredRow: (row: number) => void;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
  onListItemClick: (task: Task) => void;
  onDoubleClick: (task: Task) => void;
}> = ({
  rowHeight,
  tasks,
  fontFamily,
  fontSize,
  // locale,
  hoverColor,
  readOnly,
  visibleColumns,
  hoveredRow,
  setHoveredRow,
  onListItemClick,
  onDoubleClick,
}) => {
  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        borderRight: 'solid 3px #e6e4e4',
      }}
    >
      {tasks.map((t, index) => {
        return (
          <div
            className={`${styles.taskListTableRow} gantt-task-row` }
            style={{
              height: rowHeight,
              cursor: "pointer",
              backgroundColor: hoveredRow === index ? hoverColor : "",
            }}
            key={`${t.id}row`}
            onClick={() => onListItemClick(t)}
            onMouseEnter={() => setHoveredRow(index)}
            onMouseLeave={() => setHoveredRow(-1)}
          >
            { visibleColumns.map((column: any) => (
                <div
                  key={`${t.id}row${column.property}`}
                  className={styles.taskListCell}
                  style={{
                    minWidth: column.width,
                    maxWidth: column.width,
                  }}
                >
                  <div className="gantt-task-label">
                    {column.formatter ?  column.formatter(t[column.property], t) : t[column.property]}
                  </div>
                </div>
              ))
            }
            <div
              key={`${t.id}rowedit`}
              className={`${styles.taskListCell} gantt-task-edit`}
              style={{
                minWidth: 30,
                maxWidth: 30,
              }}
            >
              <div className="gantt-task-label" onClick={() => onDoubleClick(t)} style={{ display: 'flex' }}>
                {
                  readOnly
                    ? (
                      // @antd-icon/SelectOutlined
                      <svg viewBox="64 64 896 896" focusable="false"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h360c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H184V184h656v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V144c0-17.7-14.3-32-32-32zM653.3 599.4l52.2-52.2a8.01 8.01 0 00-4.7-13.6l-179.4-21c-5.1-.6-9.5 3.7-8.9 8.9l21 179.4c.8 6.6 8.9 9.4 13.6 4.7l52.4-52.4 256.2 256.2c3.1 3.1 8.2 3.1 11.3 0l42.4-42.4c3.1-3.1 3.1-8.2 0-11.3L653.3 599.4z" /></svg>                    )
                    : (
                      // @antd-icon/EditOutlined
                      <svg viewBox="64 64 896 896" focusable="false"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z" /></svg>
                    )
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
