import React from "react";
import styles from "./task-list-header.module.css";

export const TaskListHeaderDefault: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  visibleColumns: any[];
}> = ({ headerHeight, fontFamily, fontSize, visibleColumns }) => {
  return (
    <div
      className={styles.ganttTable}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        borderRight: 'solid 3px #e6e4e4',
      }}
    >
      <div
        className={styles.ganttTable_Header}
        style={{
          height: headerHeight - 2,
          backgroundColor: '#F0F2F5',
          border: '1px solid #e3e3e4',
        }}
      >
        { visibleColumns.map((column, index) => (
          <div
            key={"Column" + index}
            className={styles.ganttTable_HeaderItem}
            style={{
              minWidth: column.width,
              maxWidth: column.width,
              display: 'table-cell',
              verticalAlign: 'bottom',
            }}
          >
            {column.label}
          </div>
        ))}
        <div
          key="Column Edit"
          className={styles.ganttTable_HeaderItem}
          style={{
            minWidth: 30,
            display: 'table-cell',
            verticalAlign: 'bottom',
          }}
        />
      </div>
    </div>
  );
};
